



















































import { computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, watch } from '@vue/composition-api'
import useUser from '@/use/user'
import { AxiosInstance } from 'axios'
import { DataOptions, DataTableHeader } from 'vuetify'

interface RowClickEventData {
  expand: (value: boolean) => void;
  headers: DataTableHeader[];
  isExpanded: boolean;
  isMobile: boolean;
  isSelected: boolean;
  item: any;
  select: (value: boolean) => void;
}

export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      default: 'current'
    }
  },

  components: {
    MCompanyFilter: () => import('@/components/molecules/m-company-filter.vue')
  },

  setup(props, { root }) {
    const { getRoleName, hasAccessTo, hasManyCompanies } = useUser({ root })

    const state = reactive({
      loading: false,
      headers: computed(() => {
        const headers =  []

        headers.push({ value: 'date', text: 'Data' })
        if (props.type !== 'current') headers.push({ value: 'author', text: 'Autor zgłoszenia', sortable: false })
        if (props.type !== 'current') headers.push({ value: 'company', text: 'Firma', sortable: false })
        headers.push({ value: 'costs', text: 'Koszt' })
        headers.push({ value: 'installment', text: 'Miesięczna rata netto', sortable: false })
        headers.push({ value: 'period', text: 'Okres' })
        headers.push({ value: 'service', text: 'Z serwisem' })
        headers.push({ value: 'insurance', text: 'Z ubezpieczeniem' })
        headers.push({ value: 'tires', text: 'Z oponami' })

        return headers
      }),
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['date'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
      },
      total: 0,
      items: [],
      interval: null as any,
      time: 0
    })

    const model = reactive({
      company: null as any
    })

    const fetchData = () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance
      const { sortBy, sortDesc, page, itemsPerPage } = state.options as DataOptions

      state.loading = true

      const filter = {
        company: model.company ? model.company.id : undefined
      }

      axiosInstance
        .get('request', { params: { sortBy, sortDesc, page, itemsPerPage, current: props.type === 'current', filter } })
        .then(({ data: { requests, total } }) => {
          state.items = requests.map((request: any, index: number) => ({ id: index, date: '', message: '', actions: null, ...request }))
          state.total = total
        })
        .catch(error => console.log(error))
        .finally(() => state.loading = false)
    }

    onMounted(fetchData)
    watch(model, () => (state.options.page = 1), { deep: true })
    watch(model, fetchData, { deep: true })
    watch(model, () => nextTick(fetchData), { deep: true })
    watch(model, () => setTimeout(fetchData, 100), { deep: true })
    watch(() => state.options, fetchData, { deep: true })

    onMounted(() => state.interval = setInterval(() => state.time = new Date().getTime(), 1000))
    onUnmounted(() => { if (state.interval) clearInterval(state.interval) })

    const onRowClick = (event: MouseEvent, data: RowClickEventData) => {
      root.$router.push({ name: 'panel.request.view', params: { id: data.item.id } })
    }

    return { state, model, fetchData, getRoleName, hasAccessTo, hasManyCompanies, onRowClick }
  }
})
