var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-request-list"},[((_vm.hasAccessTo('employee') && _vm.type !== 'current') || _vm.hasManyCompanies())?[_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('m-company-filter',{attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})],1)],1)],1),_c('v-divider')]:_vm._e(),_c('v-data-table',{attrs:{"loading":_vm.state.loading,"headers":_vm.state.headers,"items":_vm.state.items,"options":_vm.state.options,"server-items-length":_vm.state.total,"footer-props":{ itemsPerPageOptions: [10, 25, 50, 100] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.date,'calendar')))]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.author && item.author.company ? item.author.company : '---'))]}},{key:"item.author",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.author ? item.author.firstName : '---')+" "+_vm._s(item.author ? item.author.lastName : '---'))]}},{key:"item.costs",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.costs.length > 50 ? item.costs.substr(0, 50) + '...' : item.costs))]}},{key:"item.installment",fn:function(ref){
var item = ref.item;
return [(!item.installment.to)?_c('span',[_vm._v("powyżej "+_vm._s(item.installment.from)+" zł")]):_c('span',[_vm._v(_vm._s(item.installment.from)+" zł — "+_vm._s(item.installment.to)+" zł")])]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [(item.period === 1)?_c('span',[_vm._v("1 miesiąc")]):([12, 13, 14].includes(item.period) || ![2, 3, 4].includes(item.period % 10))?_c('span',[_vm._v(_vm._s(item.period)+" miesięcy")]):_c('span',[_vm._v(_vm._s(item.period)+" miesiące")])]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.service ? 'green' : 'red'}},[_vm._v(_vm._s(item.service ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.insurance",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.insurance ? 'green' : 'red'}},[_vm._v(_vm._s(item.insurance ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.tires",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.tires ? 'green' : 'red'}},[_vm._v(_vm._s(item.tires ? 'mdi-check' : 'mdi-close'))])]}},{key:"header.service",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-tools")])]}}])},[_c('span',[_vm._v("Z serwisem")])])]},proxy:true},{key:"header.insurance",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-shield-car")])]}}])},[_c('span',[_vm._v("Z ubezpieczeniem")])])]},proxy:true},{key:"header.tires",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-record-circle")])]}}])},[_c('span',[_vm._v("Z oponami")])])]},proxy:true}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }